import Vue from 'vue';
import VueLogger, { Log } from 'vuejs-logger';

const isProduction = process.env.NODE_ENV === 'production';

const options = {
  isEnabled: true,
  logLevel: isProduction ? 'error' : 'debug',
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: '|',
  showConsoleColors: true,
};

// Using any here is not great
// however encapsulating the logger here prevents doing this everywhere
Vue.use(VueLogger as any, options);
const logger: Log = (Vue as any).$log;

export default logger;
