import axios, { AxiosResponse } from 'axios';
import { logger } from '@/helpers';

export type Configuration = {
  apiUrl: string;
  mapUrl: string;
  redirectEnabled: boolean;
  disallowedSchemeCodes: string[];
  amendmentListUrl: string;
};

const getConfiguration: () => Promise<Configuration> = () =>
  axios
    .get<Configuration>('/config.json')
    .then((response: AxiosResponse) => {
      const config = response.data;
      logger.debug('Retrieved config:', config);
      return config;
    })
    .catch((err) => {
      logger.error('Failed to load config.json. Error:', err);
    });

export { getConfiguration };
