









import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component
class PageTitle extends Vue {
  public pageTitle = 'Planning Schemes and Maps';

  public pageSubtitle: string | null = '';
}

export default PageTitle;
