










import { Component, Vue } from 'vue-property-decorator';

import { RplContainer, RplRow, RplCol } from '@dpc-sdp/ripple-grid';
import { RplCardBox } from '@dpc-sdp/ripple-card';
import PdfIcon from '@/components/icons/Pdf.vue';
import RplDocumentLink from '@/components/shared/DocumentLink.vue';

@Component({
  components: {
    RplRow,
    RplCol,
    RplDocumentLink,
    PdfIcon,
    RplContainer,
    RplCardBox,
  },
})
export default class Home extends Vue {}
