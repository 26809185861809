





















import Vue from 'vue';
import Component from 'vue-class-component';
import { RplContainer, RplRow } from '@dpc-sdp/ripple-grid';
import getPageTitle from '@/helpers/page-title';
import Header from '@/components/header-footer/Header.vue';
import Footer from '@/components/header-footer/Footer.vue';
import BackToTop from '@/components/shared/BackToTop.vue';

@Component({
  components: {
    BackToTop,
    Header,
    Footer,
    RplContainer,
    RplRow,
  },
  metaInfo() {
    const title = getPageTitle(this.$route);
    return {
      title,
    };
  },
})
export default class App extends Vue {
  get schemeName() {
    return this.$route.params.schemeName || '';
  }

  get tabs() {
    return [
      {
        name: 'schemes',
        meta: 'Planning Schemes',
        route: { name: 'ordinance' },
        params: {
          schemeName: this.schemeName,
        },
      },
      {
        name: 'maps',
        meta: 'Interactive Maps',
        route: { name: 'maps' },
        params: {
          schemeName: this.schemeName,
        },
      },
    ];
  }
}
