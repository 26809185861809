















import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import MapIcon from '@/components/icons/Map.vue';
import VicGovLogo from '@/components/icons/VicGovLogo.vue';
import BannerIcon from '@/components/icons/TopBanner.vue';

@Component({
  components: {
    VicGovLogo,
    BannerIcon,

    MapIcon,
  },
})
export default class Banner extends Vue {}
