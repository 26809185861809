// import 'current-script-polyfill';

import Vue from 'vue';
import VueRouter from 'vue-router';
import VueMeta from 'vue-meta';
import RplGlobal from '@dpc-sdp/ripple-global';
import router from '@/router';
import App from '@/App.vue';
import 'viewerjs/dist/viewer.min.css';
import VueViewer from 'v-viewer';

// ripple custom options
// https://github.com/dpc-sdp/ripple/tree/develop/packages/components/Atoms/Global#rploptions
Vue.use(RplGlobal, {});
Vue.use(VueMeta, {
  refreshOnceOnNavigation: false,
});
Vue.use(VueRouter);

Vue.use(VueViewer);
Vue.config.productionTip = false;

new Vue({
  router,

  render: (h) => h(App),
}).$mount('#app');
