





import Vue from 'vue';
import { Component } from 'vue-property-decorator';

import { RplTextLink } from '@dpc-sdp/ripple-link';
import ChangeInfo from '@/components/ChangeInfo.vue';

import Map from '@/components/maps/Map.vue';

@Component({
  components: {
    ChangeInfo,
    Map,
    RplTextLink,
  },
})
export default class Maps extends Vue {
  get planningSchemeTitle(): string {
    return this.$route.params.schemeName;
  }
}
