















import Vue from 'vue';
import Component from 'vue-class-component';
import RplButton from '@dpc-sdp/ripple-button';
import VectorIcon from '../components/icons/Vector.vue';

@Component({
  name: 'PageNotFoundView',
  components: {
    RplButton,
    VectorIcon,
  },
})
export default class PageNotFoundView extends Vue {}
