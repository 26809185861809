import axios, { AxiosResponse } from 'axios';
import { logger } from '@/helpers';

export type Features = {
  browseAmendments: boolean;
};

const getFeatures: () => Promise<Features> = () =>
  axios
    .get<Features>('/toggles/planning-scheme-toggles.json')
    .then((response: AxiosResponse) => {
      const features = response.data;
      logger.debug('Retrieved feature toggles:', features);
      return features;
    })
    .catch(() => ({}));

export { getFeatures };
