















































import { Component, Vue } from 'vue-property-decorator';
import { RplSearchForm } from '@dpc-sdp/ripple-search';
import { RplContainer, RplRow, RplCol } from '@dpc-sdp/ripple-grid';
import { RplCardBox } from '@dpc-sdp/ripple-card';
import RplLink from '@dpc-sdp/ripple-link';
import RplDocumentLink from '@/components/shared/DocumentLink.vue';
import MapIcon from '@/components/icons/Map.vue';
import AlertBanner from './shared/AlertBanner.vue';

@Component({
  components: {
    AlertBanner,
    RplRow,
    RplCol,
    RplSearchForm,
    RplDocumentLink,
    RplLink,
    RplContainer,
    RplCardBox,
    MapIcon,
  },
})
export default class LinkList extends Vue {
  public searchPlaceholder = 'Please feel free to search scheme';

  public buttonHiddenLabel = 'Search';

  public getSearchResults(value) {
    this.$emit('searchUpdated', value);
  }

  public itemWithPath(item) {
    const nameVic =
      item.name === 'Victoria Planning Provisions'
        ? ' All Ordinance'
        : ' PS All Ordinance';
    const urlVic =
      item.name === 'Victoria Planning Provisions'
        ? ' All Ordinance.pdf'
        : ' PS All Ordinance.pdf';
    return {
      url: `/combined/${item.name + urlVic}`,
      download: `${item.name + nameVic}`,
      ...item,
    };
  }

  public sectionHeaders = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'K',
    'L',
    'M',
    'N',
    'P',
    'Q',
    'S',
    'T',
    'V',
    'W',
    'Y',
  ];

  public groupedSchemes = {
    A: [
      {
        firstLetter: 'A',
        schemeID: 'alpi',
        name: 'Alpine',
        state: { activeFlag: true },
        amendmentNumber: 'VC210',
        gazettalDate: '04/05/2022',
        extension: 'pdf',
      },
      {
        firstLetter: 'A',
        schemeID: 'alpr',
        name: 'Alpine Resorts',
        state: { activeFlag: true },
        amendmentNumber: 'VC219',
        gazettalDate: '22/03/2022',

        extension: 'pdf',
      },
      {
        firstLetter: 'A',
        schemeID: 'arat',
        name: 'Ararat',
        state: { activeFlag: true },
        amendmentNumber: 'VC219',
        gazettalDate: '22/03/2022',

        extension: 'pdf',
      },
    ],
    B: [
      {
        firstLetter: 'B',
        schemeID: 'ball',
        name: 'Ballarat',
        state: { activeFlag: true },
        amendmentNumber: 'SGC48',
        gazettalDate: '23/03/2022',

        extension: 'pdf',
      },
      {
        firstLetter: 'B',
        schemeID: 'bany',
        name: 'Banyule',
        state: { activeFlag: true },
        amendmentNumber: 'VC219',
        gazettalDate: '22/03/2022',

        extension: 'pdf',
      },
      {
        firstLetter: 'B',
        schemeID: 'basc',
        name: 'Bass Coast',
        state: { activeFlag: true },
        amendmentNumber: 'VC219',
        gazettalDate: '22/03/2022',

        extension: 'pdf',
      },
      {
        firstLetter: 'B',
        schemeID: 'bawb',
        name: 'Baw Baw',
        state: { activeFlag: true },
        amendmentNumber: 'SGC48',
        gazettalDate: '23/03/2022',

        extension: 'pdf',
      },
      {
        firstLetter: 'B',
        schemeID: 'bays',
        name: 'Bayside',
        state: { activeFlag: true },
        amendmentNumber: 'SGC48',
        gazettalDate: '23/03/2022',

        extension: 'pdf',
      },
      {
        firstLetter: 'B',
        schemeID: 'bena',
        name: 'Benalla',
        state: { activeFlag: true },
        amendmentNumber: 'GC157',
        gazettalDate: '25/03/2022',

        extension: 'pdf',
      },
      {
        firstLetter: 'B',
        schemeID: 'boro',
        name: 'Boroondara',
        state: { activeFlag: true },
        amendmentNumber: 'VC219',
        gazettalDate: '22/03/2022',

        extension: 'pdf',
      },
      {
        firstLetter: 'B',
        schemeID: 'brim',
        name: 'Brimbank',
        state: { activeFlag: true },
        amendmentNumber: 'SGC48',
        gazettalDate: '23/03/2022',

        extension: 'pdf',
      },
      {
        firstLetter: 'B',
        schemeID: 'blok',
        name: 'Buloke',
        state: { activeFlag: true },
        amendmentNumber: 'VC219',
        gazettalDate: '22/03/2022',

        extension: 'pdf',
      },
    ],
    C: [
      {
        firstLetter: 'C',
        schemeID: 'camp',
        name: 'Campaspe',
        state: { activeFlag: true },
        amendmentNumber: 'VC219',
        gazettalDate: '22/03/2022',

        extension: 'pdf',
      },
      {
        firstLetter: 'C',
        schemeID: 'card',
        name: 'Cardinia',
        state: { activeFlag: true },
        amendmentNumber: 'VC210',
        gazettalDate: '04/05/2022',

        extension: 'pdf',
      },
      {
        firstLetter: 'C',
        schemeID: 'case',
        name: 'Casey',
        state: { activeFlag: true },
        amendmentNumber: 'SGC48',
        gazettalDate: '23/03/2022',

        extension: 'pdf',
      },
      {
        firstLetter: 'C',
        schemeID: 'cgol',
        name: 'Central Goldfields',
        state: { activeFlag: true },
        amendmentNumber: 'SGC48',
        gazettalDate: '23/03/2022',

        extension: 'pdf',
      },
      {
        firstLetter: 'C',
        schemeID: 'cola',
        name: 'Colac Otway',
        state: { activeFlag: true },
        amendmentNumber: 'VC219',
        gazettalDate: '22/03/2022',

        extension: 'pdf',
      },
      {
        firstLetter: 'C',
        schemeID: 'cora',
        name: 'Corangamite',
        state: { activeFlag: true },
        amendmentNumber: 'VC219',
        gazettalDate: '22/03/2022',

        extension: 'pdf',
      },
    ],
    D: [
      {
        firstLetter: 'D',
        schemeID: 'dare',
        name: 'Darebin',
        state: { activeFlag: true },
        amendmentNumber: 'SGC48',
        gazettalDate: '23/03/2022',

        extension: 'pdf',
      },
    ],
    E: [
      {
        firstLetter: 'E',
        schemeID: 'egip',
        name: 'East Gippsland',
        state: { activeFlag: true },
        amendmentNumber: 'VC219',
        gazettalDate: '22/03/2022',

        extension: 'pdf',
      },
    ],
    F: [
      {
        firstLetter: 'F',
        schemeID: 'fran',
        name: 'Frankston',
        state: { activeFlag: true },
        amendmentNumber: 'VC219',
        gazettalDate: '22/03/2022',

        extension: 'pdf',
      },
      {
        firstLetter: 'F',
        schemeID: 'fisi',
        name: 'French Island and Sandstone Island',
        state: { activeFlag: true },
        amendmentNumber: 'SGC48',
        gazettalDate: '23/03/2022',

        extension: 'pdf',
      },
    ],
    G: [
      {
        firstLetter: 'G',
        schemeID: 'gann',
        name: 'Gannawarra',
        state: { activeFlag: true },
        amendmentNumber: 'SGC48',
        gazettalDate: '23/03/2022',

        extension: 'pdf',
      },
      {
        firstLetter: 'G',
        schemeID: 'glen',
        name: 'Glen Eira',
        state: { activeFlag: true },
        amendmentNumber: 'VC219',
        gazettalDate: '22/03/2022',

        extension: 'pdf',
      },
      {
        firstLetter: 'G',
        schemeID: 'gelg',
        name: 'Glenelg',
        state: { activeFlag: true },
        amendmentNumber: 'SGC48',
        gazettalDate: '23/03/2022',

        extension: 'pdf',
      },
      {
        firstLetter: 'G',
        schemeID: 'gben',
        name: 'Greater Bendigo',
        state: { activeFlag: true },
        amendmentNumber: 'SGC48',
        gazettalDate: '23/03/2022',

        extension: 'pdf',
      },
      {
        firstLetter: 'G',
        schemeID: 'gdan',
        name: 'Greater Dandenong',
        state: { activeFlag: true },
        amendmentNumber: 'VC219',
        gazettalDate: '22/03/2022',

        extension: 'pdf',
      },
      {
        firstLetter: 'G',
        schemeID: 'ggee',
        name: 'Greater Geelong',
        state: { activeFlag: true },
        amendmentNumber: 'SGC48',
        gazettalDate: '23/03/2022',

        extension: 'pdf',
      },
      {
        firstLetter: 'G',
        schemeID: 'gshe',
        name: 'Greater Shepparton',
        state: { activeFlag: true },
        amendmentNumber: 'SGC48',
        gazettalDate: '23/03/2022',

        extension: 'pdf',
      },
      {
        firstLetter: 'G',
        schemeID: 'gumn',
        name: 'Gumnut',
        state: { activeFlag: true },
        amendmentNumber: 'VC219',
        gazettalDate: '22/03/2022',

        extension: 'pdf',
      },
    ],
    H: [
      {
        firstLetter: 'H',
        schemeID: 'hepb',
        name: 'Hepburn',
        state: { activeFlag: true },
        amendmentNumber: 'VC219',
        gazettalDate: '22/03/2022',

        extension: 'pdf',
      },
      {
        firstLetter: 'H',
        schemeID: 'hind',
        name: 'Hindmarsh',
        state: { activeFlag: true },
        amendmentNumber: 'SGC48',
        gazettalDate: '23/03/2022',

        extension: 'pdf',
      },
      {
        firstLetter: 'H',
        schemeID: 'hbay',
        name: 'Hobsons Bay',
        state: { activeFlag: true },
        amendmentNumber: 'VC219',
        gazettalDate: '22/03/2022',

        extension: 'pdf',
      },
      {
        firstLetter: 'H',
        schemeID: 'hors',
        name: 'Horsham',
        state: { activeFlag: true },
        amendmentNumber: 'VC219',
        gazettalDate: '22/03/2022',

        extension: 'pdf',
      },
      {
        firstLetter: 'H',
        schemeID: 'hume',
        name: 'Hume',
        state: { activeFlag: true },
        amendmentNumber: 'VC219',
        gazettalDate: '22/03/2022',

        extension: 'pdf',
      },
    ],
    I: [
      {
        firstLetter: 'I',
        schemeID: 'indi',
        name: 'Indigo',
        state: { activeFlag: true },
        amendmentNumber: 'VC219',
        gazettalDate: '22/03/2022',

        extension: 'pdf',
      },
    ],
    K: [
      {
        firstLetter: 'K',
        schemeID: 'king',
        name: 'Kingston',
        state: { activeFlag: true },
        amendmentNumber: 'VC219',
        gazettalDate: '22/03/2022',

        extension: 'pdf',
      },
      {
        firstLetter: 'K',
        schemeID: 'knox',
        name: 'Knox',
        state: { activeFlag: true },
        amendmentNumber: 'VC219',
        gazettalDate: '22/03/2022',

        extension: 'pdf',
      },
    ],
    L: [
      {
        firstLetter: 'L',
        schemeID: 'latr',
        name: 'Latrobe',
        state: { activeFlag: true },
        amendmentNumber: 'SGC48',
        gazettalDate: '23/03/2022',

        extension: 'pdf',
      },
      {
        firstLetter: 'L',
        schemeID: 'lodd',
        name: 'Loddon',
        state: { activeFlag: true },
        amendmentNumber: 'VC219',
        gazettalDate: '22/03/2022',

        extension: 'pdf',
      },
    ],
    M: [
      {
        firstLetter: 'M',
        schemeID: 'macr',
        name: 'Macedon Ranges',
        state: { activeFlag: true },
        amendmentNumber: 'SGC48',
        gazettalDate: '23/03/2022',

        extension: 'pdf',
      },
      {
        firstLetter: 'M',
        schemeID: 'mann',
        name: 'Manningham',
        state: { activeFlag: true },
        amendmentNumber: 'VC219',
        gazettalDate: '22/03/2022',

        extension: 'pdf',
      },
      {
        firstLetter: 'M',
        schemeID: 'mans',
        name: 'Mansfield',
        state: { activeFlag: true },
        amendmentNumber: 'SGC48',
        gazettalDate: '23/03/2022',

        extension: 'pdf',
      },
      {
        firstLetter: 'M',
        schemeID: 'mari',
        name: 'Maribyrnong',
        state: { activeFlag: true },
        amendmentNumber: 'SGC48',
        gazettalDate: '23/03/2022',

        extension: 'pdf',
      },
      {
        firstLetter: 'M',
        schemeID: 'maro',
        name: 'Maroondah',
        state: { activeFlag: true },
        amendmentNumber: 'VC219',
        gazettalDate: '22/03/2022',

        extension: 'pdf',
      },
      {
        firstLetter: 'M',
        schemeID: 'melb',
        name: 'Melbourne',
        state: {
          activeFlag: true,
          alternateUri:
            'https://planning-schemes.api.delwp.vic.gov.au/schemes/vpps/32_03.pdf',
        },
        amendmentNumber: 'C385melb',
        gazettalDate: '10/05/2022',

        extension: 'pdf',
      },
      {
        firstLetter: 'M',
        schemeID: 'melt',
        name: 'Melton',
        state: { activeFlag: true },
        amendmentNumber: 'VC219',
        gazettalDate: '22/03/2022',

        extension: 'pdf',
      },
      {
        firstLetter: 'M',
        schemeID: 'mild',
        name: 'Mildura',
        state: { activeFlag: true },
        amendmentNumber: 'VC219',
        gazettalDate: '22/03/2022',

        extension: 'pdf',
      },
      {
        firstLetter: 'M',
        schemeID: 'mith',
        name: 'Mitchell',
        state: { activeFlag: true },
        amendmentNumber: 'GC157',
        gazettalDate: '25/03/2022',

        extension: 'pdf',
      },
      {
        firstLetter: 'M',
        schemeID: 'moir',
        name: 'Moira',
        state: { activeFlag: true },
        amendmentNumber: 'SGC48',
        gazettalDate: '23/03/2022',

        extension: 'pdf',
      },
      {
        firstLetter: 'M',
        schemeID: 'mona',
        name: 'Monash',
        state: { activeFlag: true },
        amendmentNumber: 'VC219',
        gazettalDate: '22/03/2022',

        extension: 'pdf',
      },
      {
        firstLetter: 'M',
        schemeID: 'moon',
        name: 'Moonee Valley',
        state: { activeFlag: true },
        amendmentNumber: 'SGC48',
        gazettalDate: '23/03/2022',

        extension: 'pdf',
      },
      {
        firstLetter: 'M',
        schemeID: 'moor',
        name: 'Moorabool',
        state: { activeFlag: true },
        amendmentNumber: 'VC219',
        gazettalDate: '22/03/2022',

        extension: 'pdf',
      },
      {
        firstLetter: 'M',
        schemeID: 'more',
        name: 'Moreland',
        state: { activeFlag: true },
        amendmentNumber: 'VC219',
        gazettalDate: '22/03/2022',

        extension: 'pdf',
      },
      {
        firstLetter: 'M',
        schemeID: 'morn',
        name: 'Mornington Peninsula',
        state: { activeFlag: true },
        amendmentNumber: 'SGC48',
        gazettalDate: '23/03/2022',

        extension: 'pdf',
      },
      {
        firstLetter: 'M',
        schemeID: 'malx',
        name: 'Mount Alexander',
        state: { activeFlag: true },
        amendmentNumber: 'VC219',
        gazettalDate: '22/03/2022',

        extension: 'pdf',
      },
      {
        firstLetter: 'M',
        schemeID: 'moyn',
        name: 'Moyne',
        state: { activeFlag: true },
        amendmentNumber: 'SGC48',
        gazettalDate: '23/03/2022',

        extension: 'pdf',
      },
      {
        firstLetter: 'M',
        schemeID: 'muri',
        name: 'Murrindindi',
        state: { activeFlag: true },
        amendmentNumber: 'SGC48',
        gazettalDate: '23/03/2022',

        extension: 'pdf',
      },
    ],
    N: [
      {
        firstLetter: 'N',
        schemeID: 'nill',
        name: 'Nillumbik',
        state: { activeFlag: true },
        amendmentNumber: 'SGC48',
        gazettalDate: '23/03/2022',

        extension: 'pdf',
      },
      {
        firstLetter: 'N',
        schemeID: 'ngra',
        name: 'Northern Grampians',
        state: { activeFlag: true },
        amendmentNumber: 'VC219',
        gazettalDate: '22/03/2022',

        extension: 'pdf',
      },
    ],
    P: [
      {
        firstLetter: 'P',
        schemeID: 'pmel',
        name: 'Port of Melbourne',
        state: { activeFlag: true },
        amendmentNumber: 'SGC48',
        gazettalDate: '23/03/2022',

        extension: 'pdf',
      },
      {
        firstLetter: 'P',
        schemeID: 'port',
        name: 'Port Phillip',
        state: { activeFlag: true },
        amendmentNumber: 'SGC48',
        gazettalDate: '23/03/2022',

        extension: 'pdf',
      },
      {
        firstLetter: 'P',
        schemeID: 'pyrn',
        name: 'Pyrenees',
        state: { activeFlag: true },
        amendmentNumber: 'SGC48',
        gazettalDate: '23/03/2022',

        extension: 'pdf',
      },
    ],
    Q: [
      {
        firstLetter: 'Q',
        schemeID: 'quen',
        name: 'Queenscliffe',
        state: { activeFlag: true },
        amendmentNumber: 'SGC48',
        gazettalDate: '23/03/2022',

        extension: 'pdf',
      },
    ],
    S: [
      {
        firstLetter: 'S',
        schemeID: 'sgip',
        name: 'South Gippsland',
        state: { activeFlag: true },
        amendmentNumber: 'SGC48',
        gazettalDate: '23/03/2022',

        extension: 'pdf',
      },
      {
        firstLetter: 'S',
        schemeID: 'sgra',
        name: 'Southern Grampians',
        state: { activeFlag: true },
        amendmentNumber: 'SGC48',
        gazettalDate: '23/03/2022',

        extension: 'pdf',
      },
      {
        firstLetter: 'S',
        schemeID: 'ston',
        name: 'Stonnington',
        state: { activeFlag: true },
        amendmentNumber: 'VC219',
        gazettalDate: '22/03/2022',

        extension: 'pdf',
      },
      {
        firstLetter: 'S',
        schemeID: 'strb',
        name: 'Strathbogie',
        state: { activeFlag: true },
        amendmentNumber: 'GC157',
        gazettalDate: '25/03/2022',

        extension: 'pdf',
      },
      {
        firstLetter: 'S',
        schemeID: 'surf',
        name: 'Surf Coast',
        state: { activeFlag: true },
        amendmentNumber: 'SGC48',
        gazettalDate: '23/03/2022',

        extension: 'pdf',
      },
      {
        firstLetter: 'S',
        schemeID: 'swan',
        name: 'Swan Hill',
        state: { activeFlag: true },
        amendmentNumber: 'SGC48',
        gazettalDate: '23/03/2022',

        extension: 'pdf',
      },
    ],
    T: [
      {
        firstLetter: 'T',
        schemeID: 'towg',
        name: 'Towong',
        state: { activeFlag: true },
        amendmentNumber: 'SGC48',
        gazettalDate: '23/03/2022',

        extension: 'pdf',
      },
    ],
    V: [
      {
        firstLetter: 'V',
        schemeID: 'vpp',
        name: 'Victoria Planning Provisions',
        state: { activeFlag: true },
        amendmentNumber: 'VC219',
        gazettalDate: '22/03/2022',

        extension: 'pdf',
      },
    ],
    W: [
      {
        firstLetter: 'W',
        schemeID: 'wang',
        name: 'Wangaratta',
        state: { activeFlag: true },
        amendmentNumber: 'GC157',
        gazettalDate: '25/03/2022',

        extension: 'pdf',
      },
      {
        firstLetter: 'W',
        schemeID: 'warr',
        name: 'Warrnambool',
        state: { activeFlag: true },
        amendmentNumber: 'VC219',
        gazettalDate: '22/03/2022',

        extension: 'pdf',
      },
      {
        firstLetter: 'W',
        schemeID: 'well',
        name: 'Wellington',
        state: { activeFlag: true },
        amendmentNumber: 'SGC48',
        gazettalDate: '23/03/2022',

        extension: 'pdf',
      },
      {
        firstLetter: 'W',
        schemeID: 'wwim',
        name: 'West Wimmera',
        state: { activeFlag: true },
        amendmentNumber: 'VC219',
        gazettalDate: '22/03/2022',

        extension: 'pdf',
      },
      {
        firstLetter: 'W',
        schemeID: 'whse',
        name: 'Whitehorse',
        state: { activeFlag: true },
        amendmentNumber: 'VC219',
        gazettalDate: '22/03/2022',

        extension: 'pdf',
      },
      {
        firstLetter: 'W',
        schemeID: 'wsea',
        name: 'Whittlesea',
        state: { activeFlag: true },
        amendmentNumber: 'C259wsea',
        gazettalDate: '10/05/2022',

        extension: 'pdf',
      },
      {
        firstLetter: 'W',
        schemeID: 'wdon',
        name: 'Wodonga',
        state: { activeFlag: true },
        amendmentNumber: 'GC157',
        gazettalDate: '25/03/2022',

        extension: 'pdf',
      },
      {
        firstLetter: 'W',
        schemeID: 'wynd',
        name: 'Wyndham',
        state: { activeFlag: true },
        amendmentNumber: 'SGC48',
        gazettalDate: '23/03/2022',

        extension: 'pdf',
      },
    ],
    Y: [
      {
        firstLetter: 'Y',
        schemeID: 'yara',
        name: 'Yarra',
        state: { activeFlag: true },
        amendmentNumber: 'SGC48',
        gazettalDate: '23/03/2022',

        extension: 'pdf',
      },
      {
        firstLetter: 'Y',
        schemeID: 'yran',
        name: 'Yarra Ranges',
        state: { activeFlag: true },
        amendmentNumber: 'VC219',
        gazettalDate: '22/03/2022',

        extension: 'pdf',
      },
      {
        firstLetter: 'Y',
        schemeID: 'yari',
        name: 'Yarriambiack',
        state: { activeFlag: true },
        amendmentNumber: 'VC219',
        gazettalDate: '22/03/2022',

        extension: 'pdf',
      },
    ],
  };
}
