













import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { RplSiteFooter } from '@dpc-sdp/ripple-site-footer';

const contactPlanningSupport = {
  text: 'Contact planning support',
  children: [
    {
      text: '1800 789 386 (select option 1)',
      url: 'tel:1800789386',
    },
    {
      text: 'planning.support@delwp.vic.gov.au',
      url: 'mailto:planning.support@delwp.vic.gov.au',
    },
    {
      text: 'Planning general enquiries',
      url: 'https://www.planning.vic.gov.au/contact-planning',
    },
  ],
};

const navNationalRelay = {
  text: 'Deaf, hearing or speech impaired?',
  children: [
    {
      text: 'National Relay Service: 133 677',
      url: 'tel:133677',
    },
    {
      text: 'www.relayservice.gov.au',
      url: 'http://www.relayservice.gov.au',
    },
  ],
};

const traditionalOwners =
  'We acknowledge and respect Victorian Traditional Owners as the original custodians of Victoria’s land and waters, their unique ability to care for Country and deep spiritual connection to it.';
const elders =
  'We honour Elders past and present whose knowledge and wisdom has ensured the continuation of culture and traditional practices.';
const commitment =
  'We are committed to genuinely partner, and meaningfully engage, with Victoria’s Traditional Owners and Aboriginal communities to support the protection of Country, the maintenance of spiritual and cultural practices and their broader aspirations in the 21st century and beyond.';
const acknowledgement = `${traditionalOwners} ${elders}\n\n${commitment}`;

const links = [
  {
    text: 'Accessibility',
    url: 'https://www.delwp.vic.gov.au/accessibility',
  },
  {
    text: 'Copyright',
    url: 'https://www.delwp.vic.gov.au/copyright',
  },
  {
    text: 'Disclaimer',
    url: 'https://www.delwp.vic.gov.au/disclaimer',
  },
  {
    text: 'Privacy',
    url: 'https://www.delwp.vic.gov.au/privacy',
  },
];

@Component({
  components: {
    RplSiteFooter,
  },
  data() {
    return {
      navs: [contactPlanningSupport, navNationalRelay],
      acknowledgement,
      caption: 'Illustration by Tom Day',
      copyright: '© Copyright State Government of Victoria',
      links,
    };
  },
})
class Footer extends Vue {}

export default Footer;
