import { schemesClient, SchemesClient } from '../api';
import { getConfiguration } from './config';
import { getFeatures } from './feature-toggle';

const config: Promise<any> = getConfiguration();
const features: Promise<any> = getFeatures();

export interface ApiDeps {
  schemesBaseUrl: string;
  schemesClient: SchemesClient;
}

const api: Promise<ApiDeps> = config.then(({ apiUrl }) => ({
  schemesBaseUrl: apiUrl,
  schemesClient: schemesClient(apiUrl),
}));

const mapUrl: Promise<string> = config.then((it) => it.mapUrl);

const redirectEnabled: Promise<boolean> = config.then(
  ({ redirectEnabled: redirect }) => redirect,
);

const disallowedSchemeList: Promise<string[]> = config.then(
  ({ disallowedSchemeCodes: schemeList }) => schemeList,
);

const amListUrl: Promise<string> = config.then((it) => it.amendmentListUrl);

const oldBaseUrl =
  'https://www.planning.vic.gov.au/schemes-and-amendments/browse-planning-scheme';

const browseAllSchemesUrl =
  'https://www.planning.vic.gov.au/schemes-and-amendments/browse-amendments';

export {
  api,
  mapUrl,
  browseAllSchemesUrl,
  oldBaseUrl,
  redirectEnabled,
  features,
  disallowedSchemeList,
  amListUrl,
};
