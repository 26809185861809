import qs from 'qs';
import axios, { AxiosResponse } from 'axios';
import { OrdinanceReference } from '@/models';

import { logger } from '@/helpers';

const successHandler = (response) => response;
const errorHandler = (error) => {
  logger.debug('axios error:', JSON.parse(JSON.stringify(error)));
  return Promise.reject(error);
};

type AxiosPromise<T> = Promise<AxiosResponse<T>>;
export interface SchemesClient {
  fetchOrdinanceIdForMap: (
    schemeId: string,
    level: string,
    mapCode: string,
  ) => AxiosPromise<OrdinanceReference>;
}

const schemesClient: (baseURL: string) => SchemesClient = (baseURL: string) => {
  // Override default encoding in axios as mention in
  // https://github.com/axios/axios/issues/1111
  axios.defaults.paramsSerializer = (params) =>
    qs.stringify(params, { indices: true }); // param=value1&param=value2
  const client = axios.create({ baseURL });

  client.interceptors.response.use(successHandler, errorHandler);
  return {
    fetchOrdinanceIdForMap: (schemeCode, level, mapCode) =>
      client.get<OrdinanceReference>(
        `/ordinance/scheme/${schemeCode}/map-ref`,
        {
          params: { level, mapCode },
        },
      ),
  };
};

export { schemesClient };
