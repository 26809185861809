





















import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { RplAlertBase } from '@dpc-sdp/ripple-alert';
import { RplTextLink } from '@dpc-sdp/ripple-link';

@Component({
  components: {
    RplAlertBase,
    RplTextLink,
  },
})
export default class AlertBanner extends Vue {}
