import VueRouter, { RouteConfig } from 'vue-router';
import Maps from '@/views/scheme/Maps.vue';
import LinkList from '@/components/LinkList.vue';
import Home from '@/views/Home.vue';
import PageNotFound from '@/views/PageNotFound.vue';

const routes: RouteConfig[] = [
  {
    path: '/:schemeName?',
    name: 'home',
    component: Home,
    redirect: {
      name: 'schemes',
    },
    children: [
      {
        path: 'ordinance',
        name: 'schemes',
        component: LinkList,
      },
      {
        path: 'maps',
        name: 'maps',
        component: Maps,
      },
    ],
  },
  {
    path: '/:notFound(.*)',
    name: '404',
    component: PageNotFound,
  },
];

const vueRouter = new VueRouter({
  mode: 'history',
  routes,
});

export default vueRouter;
