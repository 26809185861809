




import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class Redirect extends Vue {
  @Prop() public options;

  mounted() {
    this.$router.push(this.options);
  }
}
