




import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import Redirect from '@/components/shared/Redirect.vue';
import { mapUrl } from '@/config';
import { lgaCodeForSchemeName } from './lga-code';

interface MapMessage {
  type: string;
  value: any;
}

const postToMap = (payload: MapMessage) => {
  const iframe: HTMLIFrameElement | null = document.querySelector('iframe#map');
  if (iframe?.contentWindow) {
    iframe.contentWindow.postMessage(JSON.stringify(payload), '*');
  }
};

const setLGA = (schemeName: string) => {
  postToMap({ type: 'setLGA', value: lgaCodeForSchemeName(schemeName) });
};

@Component({
  components: { Redirect },
})
export default class Map extends Vue {
  @Prop({ type: String, required: true }) schemeName;

  private iframeSrc = '';

  get mapUrl() {
    return this.iframeSrc;
  }

  get selectedLgaExists(): boolean {
    return lgaCodeForSchemeName(this.schemeName) !== undefined;
  }

  get notFoundUrl(): string {
    return `/404/${this.schemeName}/maps`;
  }

  async mounted() {
    window.addEventListener(
      'message',
      (event) => {
        const message = JSON.parse(event.data);
        if (message.type === 'MapReady') {
          this.onMapReady();
        }
      },
      false,
    );
    this.iframeSrc = await mapUrl;
  }

  onMapReady() {
    setLGA(this.schemeName);
  }
}
